import { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import {
  AdminFeedItemStatusEnum,
  UpdateAdminFeedItemStatusInteractionMutation,
  useUpdateAdminFeedItemStatusInteractionMutation,
} from "../../schema";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";

type UpdateAdminFeedItemStatusData = {
  status: AdminFeedItemStatusEnum;
  comment: string;
};

export interface AdminFeedItemStatusInteractionModalProps extends ModalProps {
  previousStatus?: AdminFeedItemStatusEnum;
  adminFeedItemId: string;
  onCompleted: (data: UpdateAdminFeedItemStatusInteractionMutation) => void;
}

export const AdminFeedItemStatusInteractionModal: React.FC<AdminFeedItemStatusInteractionModalProps> = ({
  previousStatus,
  adminFeedItemId,
  onCompleted,
  onClickOutside,
  ...rest
}) => {
  const [updateAdminFeedItemStatusInteraction, updateAdminFeedItemStatusInteractionResult] =
    useUpdateAdminFeedItemStatusInteractionMutation({
      refetchQueries: ["CollaborationById", "AdminFeedItemById", "UserAdminFeedItemStatusInteraction"],
      awaitRefetchQueries: true,
      onCompleted,
    });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "status",
        label: "Status",
        options: getEnumFormOptions(AdminFeedItemStatusEnum).filter(
          (status) => status.value !== AdminFeedItemStatusEnum.STAGING && status.value !== previousStatus,
        ),
        rules: { required: "Please provide new status review value" },
      },
      {
        field: "text",
        name: "comment",
        label: "Comment",
      },
    ],
    [previousStatus],
  );

  const onSubmit: SubmitHandler<UpdateAdminFeedItemStatusData> = async (data) => {
    await updateAdminFeedItemStatusInteraction({
      variables: {
        adminFeedItemId: adminFeedItemId,
        status: data.status,
        comment: data.comment,
      },
    });
  };

  return (
    <Modal
      {...rest}
      title="Review admin feed item"
      error={updateAdminFeedItemStatusInteractionResult.error}
      onClickOutside={onClickOutside}
    >
      <GeneratedForm
        loading={updateAdminFeedItemStatusInteractionResult.loading}
        error={updateAdminFeedItemStatusInteractionResult.error}
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
