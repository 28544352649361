import { sentenceCase } from "change-case";
import React from "react";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { config } from "../../config";
import { ADMIN_VIEW_PATH } from "../../routes";
import { CollaborationTypeEnum, AdminPaymentInfoFragment } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { AdminPaymentDetailsProps } from "./AdminPaymentDetailsView";

export const AdminPaymentDetailsInfo: React.FC<AdminPaymentDetailsProps> = ({ adminPayment, viewer }) => (
  <WithCollaboration referenceId={adminPayment.id} type={CollaborationTypeEnum.PAYMENT} viewer={viewer}>
    <NameValueList items={getAdminPaymentNameValueList(adminPayment)} />
  </WithCollaboration>
);

export function getAdminPaymentNameValueList(adminPayment: AdminPaymentInfoFragment): NameValueListItem[] {
  const isStripeTestEnvironment = new RegExp(/cs_test_/gm).test(adminPayment.stripeSessionId);
  const revenuecatProjectId = config.revenuecatProjectId;
  const revenuecatLink =
    revenuecatProjectId && adminPayment.revenuecatCustomerId
      ? `https://app.revenuecat.com/customers/${revenuecatProjectId}/${adminPayment.revenuecatCustomerId}`
      : undefined;
  const attachedUserLink = adminPayment.attachedUserId
    ? buildUrl(ADMIN_VIEW_PATH, { menu: "users", page: "user", id: adminPayment.attachedUserId })
    : undefined;

  return [
    {
      name: "Payment id",
      value: adminPayment.id,
    },
    {
      name: "Session id",
      value: adminPayment.paymentSessionId,
    },
    {
      name: "Stripe customer id",
      value: adminPayment.stripeCustomerId ? (
        <>
          <a
            href={getStripeCustomerUrl(adminPayment.stripeCustomerId, isStripeTestEnvironment)}
            target="_blank"
            rel="noreferrer"
          >
            {adminPayment.stripeCustomerId}
          </a>
        </>
      ) : (
        "n/a"
      ),
    },
    {
      name: "Stripe subscription id",
      value: adminPayment.stripeSubscriptionId ?? "n/a",
    },
    {
      name: "Stripe payment intent id",
      value: adminPayment.stripePaymentIntentId ?? "n/a",
    },
    {
      name: "Stripe session id",
      value: adminPayment.stripeSessionUrl ? (
        <>
          <a href={adminPayment.stripeSessionUrl} target="_blank" rel="noreferrer">
            {adminPayment.stripeSessionId}
          </a>
        </>
      ) : (
        "n/a"
      ),
    },
    {
      name: "Stripe email",
      value: adminPayment.stripeEmail ?? "n/a",
    },
    {
      name: "Landing Page source",
      value: adminPayment.stripeSource ?? "n/a",
    },
    {
      name: "Revenuecat profile",
      value: revenuecatLink ? (
        <>
          <a href={revenuecatLink} target="_blank" rel="noreferrer">
            {revenuecatLink}
          </a>
        </>
      ) : (
        "n/a"
      ),
    },
    {
      name: "Product",
      value: adminPayment.productData.name,
    },
    {
      name: "Payment status",
      value: sentenceCase(adminPayment.status),
    },
    {
      name: "Payment status comment",
      value: adminPayment.statusComment ? adminPayment.statusComment : "n/a",
    },
    {
      name: "Is used",
      value: adminPayment.isUsed ? "Yes" : "No",
    },
    {
      name: "Attached user",
      value: attachedUserLink ? (
        <>
          <a href={attachedUserLink} target="_blank" rel="noreferrer">
            {adminPayment.attachedUserId ?? "n/a"}
          </a>
        </>
      ) : (
        "n/a"
      ),
    },
    {
      name: "Created",
      value: adminPayment.createdDate,
    },
    {
      name: "Updated",
      value: adminPayment.updatedDate,
    },
  ];
}

const getStripeCustomerUrl = (customerId: string, isTestEnvironment: boolean) => {
  if (isTestEnvironment) {
    return `https://dashboard.stripe.com/test/customers/${customerId}`;
  }

  return `https://dashboard.stripe.com/customers/${customerId}`;
};
